<template>
  <div class="main">
    <img
      class="topImg"
      src="https://file.ai-me.cn/static/20240329/画板 1.png"
      alt="top"
    />
    <img
      @click="gotoActivityRule()"
      class="topAside"
      src="https://file.ai-me.cn/static/20240329/组 1175.png"
      alt="aside"
    />

    <div class="fuli">
      <img
        class="fuli-one"
        src="https://file.ai-me.cn/static/20240329/福利1.png"
        alt="fuli-one"
      />
      <img
        class="fuli-two"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/monthlyHotVipNTo2_zhengxing/card_01.png"
        alt="fuli-two"
      />
      <img
        class="huafeiquan"
        src="https://file.ai-me.cn/static/20240329/话费.png"
        alt="huafeiquan"
      />

      <input type="tel"  v-model="form.phone" class="phoneNumber" maxlength="11" placeholder="请输入手机号" />

      <div class="code">
        <input type="text" class="phoneCode" placeholder="请输入验证码" />
        <button class="getCodeButton">
          <span class="code" v-show="sendAuthCode" @click="getAuthCode">
            获取验证码
          </span>
          <span class="code" v-show="!sendAuthCode">
            <span>{{ auth_time }}秒</span>重新获取
          </span>
        </button>
      </div>

      <div class="bottom-button">
        <img
          class="button"
          src="https://file.ai-me.cn/static/20240329/btn.png"
          alt="button"
        />
        <img
          class="splice"
          src="https://file.ai-me.cn/static/20240329/组 1172.png"
          alt="splice"
        />

        <!-- <img class="check" src="images/unchecked.png" alt="unchecked"> -->
        <div class="agree">
          <img
            class="check"
            src="https://file.ai-me.cn/static/20240329/图层 1129.png"
            alt="checked"
          />
          <div class="text">
            <span>我已阅读并同意</span>
            <span class="userRule" @click="gotoUserRule()"
              >《随心陶服务协议》</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCode } from "@/api/index";

export default {
  data() {
    return {
      sendAuthCode: true /*布尔值，通过v-show控制显示‘获取按钮'还是‘倒计时' */,
      auth_time: 0 /*倒计时 计数器*/,
      form: {
        phone: "",
        code: "",
      },
      checked: false,
      state: false,
      ip: "",
    };
  },
  methods: {
    // 验证码
    async getAuthCode() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone } = this.form;
      if (!filterPhone.test(phone)) {
        this.$dialog.alert({ message: "手机号错误" });
        return;
      }
      this.$toast.loading({
        duration: 0,
        forbidClick: true,
        mask: true,
        message: "加载中...",
      });
      const res = await getCode(this.ip, { phone_number: phone });
      this.$toast.clear();
      if (res.code != 0) {
        this.$dialog.alert({ message: res.message });
      } else {
        // 设置倒计时秒
        this.sendAuthCode = false;
        this.auth_time = 60;
        let auth_timetimer = setInterval(() => {
          this.auth_time--;
          if (this.auth_time <= 0) {
            this.sendAuthCode = true;
            clearInterval(auth_timetimer);
          }
        }, 1000);
      }
    },
    gotoActivityRule() {
      this.$router.replace("/monthlyHotVipNTo2_zhengxing/activity.vue");
    },
    gotoUserRule() {
      this.$router.replace("/monthlyHotVipNTo2_zhengxing/userRule.vue");
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main {
  text-align: center;
  position: relative;
  background-color: #fdf8f1;
}

.topImg {
  width: 100%;
  height: 100%;
}
.topAside {
  width: 40px;
  position: absolute;
  top: 15%;
  right: 0;
}

.fuli {
  position: absolute;
  top: 90%;
  left: 10px;
  right: 10px;
  background-color: #fdf8f1;
}

.fuli .fuli-one {
  width: 100%;
}
.fuli .fuli-two {
  width: 100%;
  margin-top: 10px;
}
.fuli .huafeiquan {
  width: 100%;
  margin-top: 10px;
}

/* 输入框部分begin */
.phoneNumber {
  margin-top: 20px;
  width: 85%;
  height: 100px;
  border: none;
  border-radius: 100px;
  /* padding: 0 20px; */
}

.phoneNumber:focus {
  outline: none;
}
.phoneCode:focus {
  outline: none;
}

.phoneNumber::placeholder {
  color: #8d93a5;
  padding-left: 10px;
}
.phoneCode::placeholder {
  color: #8d93a5;
  padding-left: 10px;
}

.phoneCode {
  width: 85%;
  /* padding: 0 20px; */
  height: 100px;
  border: none;
  border-radius: 100px;
}

.code {
  margin-top: 10px;
  position: relative;
  margin-bottom: 20px;
}
.getCodeButton {
  background: linear-gradient(to right, #fbcc8e, #fbb69e);
  border: none;
  border-radius: 0px 100px 100px 0px;
  height: 102px;
  cursor: pointer;
  position: absolute;
  right: 7%;
  top: 0%;
  color: #936844;
}
/* 输入框部分end */

.bottom-button {
  position: relative;
  display: flex;
  justify-content: center;
}
.button {
  width: 90%;
  position: absolute;
}
.splice {
  width: 100%;
}
.bottom-button .agree {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20%;
  width: 100%;
}
.bottom-button .agree .check {
  width: 3%;
  margin-right: 5px;
}

.bottom-button .agree .text {
  font-size: 20px;
}

.userRule-link {
  text-decoration: none;
}

.bottom-button .agree .text .userRule {
  color: #ff7510;
}
</style>
